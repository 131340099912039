import $ from "jquery";
import http from "@/api/axios";
export default {
  name: "",
  components: {},
  data() {
    return {
      activeCase: false,
      newsList:'',
      paginationParams: {
        limit: 12,
        page: 1
      },
      currentPage:1,
      total: 0,
      index:Number,
      contentType:null,
      newsLink:null,
      height:'100px'
    };
  },
  methods: {
    alignCenter() {
      const w = document.body.clientWidth;
      const boxes = $(".cover-name")[0];
      if (boxes) {
        const boxWid = boxes.scrollWidth; //获取div的宽
        boxes.style.left = w / 2 - boxWid / 2 + "px";
      }
    },
    getNews(){
        http.get("news/getOne", {
          params: {
            id:this.index,
          }
        }).then((res)=>{
          this.newsList = res.mainBody;
          this.contentType = res.contentType;
          this.newsLink = res.newsLink;
          let height = res.height + 'px';
          if(height){
            this.height = height
          }
        })
        
    },
    // setIframeHeight(iframe){
    //   if(iframe){
    //     let iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
    //     if(iframeWin.document.body){
    //       iframe.height = iframeWin.document.documentElement.scrollHeight || iframeWin.document.body.scrollHeight;
    //     }
    //   }
    // }    
  },
  mounted() {
    const _this = this;
    _this.alignCenter();
    setTimeout(() => {
      this.index  = this.$route.params.id;
      _this.getNews();
    },);
    window.onresize = function() {
      _this.alignCenter();
    };
    // let testFrame = document.getElementById('Frame');
    // testFrame.addEventListener('load', function() {
    //   testFrame.style.height = _this.getHeight();
    // });

  },
  computed: {},
  // watch: {
  //   $route() {
  //     setTimeout(() => {
  //       let kind = sessionStorage.getItem('kind');
  //       this.getNews(kind);
  //     },);
  //   }
  // }
};